.container {
  border: 1px solid var(--gray07);
  background: #fff;
  border-radius: 6px;
  margin-bottom: 20px;

  .toggle {
    &.active .toggleIconWrapper {
      transform: rotate(180deg);
    }
  }
}

.toggle {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 32px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: var(--brand-onPrimary);
  }

  &.active {
    padding-bottom: 16px;
  }
}

.toggleIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  transition: 0.1s;
}

.content {
  max-height: 0;
  transition: max-height 0.3s, opacity 0.3s, padding 0.1s;
  overflow: hidden;
  opacity: 0;
  padding: 0 16px;

  &.active {
    padding: 0 32px 32px;
    max-height: 2000px;
    opacity: 1;
  }
}

.title {
  margin-right: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.emptyNotice {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}