@import "src/assets/styles/mixines";

.container {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.title {
  margin-bottom: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 500;
}

.checkbox {
  margin-right: 8px;
}

.input {
  width: 70px;
  margin-right: 8px;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include --tablet {
    flex-direction: row;
    align-items: center;
  }
}

.btn {
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @include --tablet {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
}
