@import "src/assets/styles/mixines";

.container {
  margin-bottom: 16px;
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.items {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background: var(--slate-100);

  @include --tablet {
    flex-direction: row;
  }
}

.blockItem {
  display: flex;
  flex: 1;
  border-radius: 8px;
  padding: 8px;
  background: var(--brand-white);

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @include --tablet {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
}

.blockItemIconWrapper {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.blockItemCount {
  margin-bottom: 4px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.blockItemLabel {
  margin-bottom: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.blockItemValue {
  color: var(--gray60);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
