@import "src/assets/styles/mixines";

.wrapper {
  padding-top: 85px;
  // max-width: 1080px;
  max-width: 1112px;
  margin: 0 auto;

  @include --tablet-s {
    max-width: 1160px;
  }

  @include --tablet-l {
    padding-top: 56px;
  }
}

.header {
  margin-bottom: 48px;
  padding: 16px;
  border-radius: 16px;
  background: var(--accent304);

  @include --tablet-s {
    padding: 24px;
  }
}

.title {
  max-width: 900px;
  text-align: center;
  margin: 0 auto 16px;
  color: var(--text-black);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;

  @include --tablet-s {
    margin-bottom: 24px;
  }

  @include --tablet-l {
    font-size: 36px;
    line-height: 45px;
  }
}

.headerRow {
  display: flex;
  flex-direction: column;

  @include --tablet-l {
    flex-direction: row;
  }
}

.rowBlock {
  padding: 16px;
  border-radius: 8px;
  background: var(--brand-white);

  &:first-child {
    flex: 1;
    margin-bottom: 16px;
  }

  &:last-child {
    flex: 1;
  }

  @include --tablet-l {
    &:first-child {
      flex: 1;
      margin-right: 16px;
      margin-bottom: 0;
    }

    &:last-child {
      flex: 1;
      max-width: 394px;
    }
  }
}

.rowBlockTitle {
  margin-bottom: 8px;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;

  b {
    font-weight: 600;
  }
}

.rowBlockContent {
  border-radius: 8px;
  padding: 16px;
  background: var(--accent10);
}

.productsList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  @include --tablet-xs {

  }

  @include --tablet-l {

  }
}

.productItem {
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 12px;
  border-radius: 32px;

  &:nth-child(1),
  &:nth-child(4),
  &:nth-child(6) {
    background: rgba(255, 231, 217, 0.80);
    color: var(--warning120);
  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(5),
  &:nth-child(7) {
    background: var(--accent30);
    color: var(--accent120);
  }
}

.productItemIconWrapper {
  margin-right: 4px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spendRow {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:first-child {
    margin-bottom: 8px;
  }

  >*:not(:last-child) {
    margin-right: 4px;
  }
}

.grayText {
  color: var(--gray60);
}

.spendValue {
  margin-left: auto;
}

.spendMarker {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.blue {
    background: var(--accent60);
  }

  &.orange {
    background: var(--warning60);
  }

  &.gray {
    background: var(--gray30);
  }
}

.subtitle {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}

.featuresList {
  display: none;
  justify-content: center;

  @include --tablet-l {
    display: flex;
    margin-bottom: 24px;
  }
}

.featureItem {
  display: flex;
  align-items: center;
  color: var(--text-black);
  font-size: 16px;
  line-height: 26px;

  &:not(:last-child) {
    margin-right: 80px;
  }
}

.featureIconCircle {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  border-radius: 50%;
  background: var(--accent100);
  color: var(--brand-white);
}

.featureIcon {
  width: 12px;
  height: 10px;
}
