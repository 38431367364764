@import "src/assets/styles/mixines";

.container {

}

.title {
  text-align: center;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.row {
  display: flex;
  flex-direction: column;

  @include --tablet-s {
    flex-direction: row;
  }
}

.paygCard {
  padding: 32px;
  border-radius: 16px;
  border: 1px solid var(--gray10);
  background: var(--brand-white);
  margin-bottom: 16px;

  @include --tablet-s {
    // max-width: 352px;
    // flex-basis: 352px;
    // flex: 1 352px;
    // width: 352px;
    margin-right: 16px;
    margin-bottom: 0;
    flex-basis: 352px;
  }
}

.paygHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.paygIconContainer {
  display: flex;
  margin-right: 8px;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;

  svg {
    width: 26px;
    height: 26px;
  }
}

.paygName {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.paygDescription {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 26px;

  b {
    font-weight: 600;
  }
}

.enterpriseCard {
  flex: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: #221F4C;
  color: var(--brand-white);

  @include --tablet-s {
    flex-direction: row;
  }
}

.enterpriseBlock {
  flex: 1;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 16px;

    @include --tablet-s {
      margin-bottom: 0;
      margin-right: 32px;
    }
  }
}

.enterpriseHeader {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.enterpriseIconContainer {
  margin-right: 8px;
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
}

.enterpriseName {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.enterpriseDescription {
  font-size: 16px;
  font-style: normal;
  line-height: 26px;

  b {
    font-weight: 600;
  }
}

.enterpriseFeatures {
  margin-bottom: 16px;
}

.enterpriseFeature {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.24px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.enterpriseFeatureIconWrapper {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.enterpriseBtn {
  margin-top: auto;
}