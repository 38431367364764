@import "src/assets/styles/mixines";

.text {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.regular {
  font-weight: normal;
};

.medium {
  font-weight: 500;
};

.bold {
  font-weight: bold;
};

.xxxl {
  font-size: 24px;
  line-height: 36px;
};

.xxl {
  font-size: 20px;
  line-height: 30px;
};

.xl {
  font-size: 18px;
  line-height: 28px;
};

.l {
  font-size: 14px;
  line-height: 22px;

  @include --tablet-s {
    font-size: 16px;
    line-height: 26px;
  }
};

.m {
  font-size: 14px;
  line-height: 22px;
};

.s {
  font-size: 12px;
  line-height: 12px;
};
