@import "src/assets/styles/mixines";

.loaderContainer {
  margin: 50px auto 0;
  color: var(--accent120);

  svg {
    width: 40px;
    height: 40px;
  }
}

.title {
  display: block;
  margin-bottom: 16px;
  text-align: center;
}

.wrapper {
  .controls {
    .tabs {
      background: transparent;
      font-weight: 400;
      flex-direction: column;

      @include --tablet-s {
        flex-direction: row;
      }
    }

    .tabsItem {
      @include --tablet-s {
        // width: 160px;
      }
    }
  }
}

.controlsLabel {
  margin-right: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 8px;
  padding: 0 16px;

  @include --tablet-s {
    padding: 0 40px;
    // margin-bottom: 20px;
  }
}

.paddingBlock {
  padding: 0 16px;
  margin-bottom: 20px;

  @include --tablet-s {
    padding: 0 40px;
    margin-bottom: 40px;
  }
}

.promoBlock {
  margin-bottom: 52px;
}

.reviewList {
  display: none;
  padding: 0 16px;

  @include --tablet-s {
    padding: 0 40px;
  }

  @include --tablet-l {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.reviewItem {
  width: 99px;

  &:not(:last-child) {
    margin-right: 24px;
  }
}

.anchorLinkBlock {
  margin-bottom: 48px;
  text-align: center;

  .anchorLink {
    color: var(--brand-primary);
    font-size: 16px;
    line-height: 26px;
    text-decoration-line: underline;
    cursor: pointer;
    transition: 0.2s;
  }
}

.featuresBlock {
  margin-bottom: 48px;
}