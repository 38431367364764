@import "src/assets/styles/mixines";

.container {
  padding: 16px 10px 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  @include --tablet-s {
    padding: 16px 12px 0;
  }

  @include --desktop-s {
    padding: 16px 0 0;
  }
}

.content {
  background: var(--brand-white);
  border: 1px solid var(--gray10);
  border-radius: 16px;
  position: relative;
  max-width: 258px;
  width: 258px;
  height: 100%;
  padding: 24px;

  &.highlighted {
    border: 2px solid var(--accent120);
  }

  &.highlightedAlt {
    border: 2px solid var(--brand-onPrimary);
  }
}

.highlight {
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate3d(-50%, -50%, 0);
  background: var(--accent120);
  border-radius: 8px;
  color: var(--brand-white);
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  font-weight: 700;
}

.highlightAlt {
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate3d(-50%, -50%, 0);
  background: var(--brand-onPrimary);
  border-radius: 8px;
  color: var(--brand-white);
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  font-weight: 700;
}

.price {
  display: flex;
  justify-content: center;
  // margin-bottom: 20px;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
}

.priceMain {
  /*display: flex;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -2px;*/
}

.priceLabel {
  //margin-bottom: 4px;
  margin-left: 6px;
  //font-size: 18px;
  //font-weight: 700;
  //line-height: 20px;
  //letter-spacing: -.5px;
  //
  //@include --phone-m {
  //  font-size: 20px;
  //}
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  // padding-bottom: 20px;
  // border-bottom: 1px solid var(--gray30);
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.5px;

  //@include --tablet {
  //  font-size: 20px;
  //  letter-spacing: -1.2px;
  //}
  //
  //@include --desktop-s {
  //  font-size: 18px;
  //  line-height: 28px;
  //}

  .highlighted {
    color: var(--accent100);
  }
}

.bombWrapper {
  margin-left: 8px;
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.trafficBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  color: var(--gray100);
  font-size: 16px;
  line-height: 26px;
}

.activeProductIconWrapper {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.info {
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
  letter-spacing: 0.24px;

  b {
    font-weight: 600;
  }
}

.monthlyPrice {
  margin-bottom: 16px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: var(--gray100);

  b {
    color: var(--brand-primary);
    font-weight: 600;
  }
}

.markers {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--gray10);

  &.active {
    background: var(--brand-onPrimary);
  }

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 28px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &.infoBold {
    font-weight: 700;
  }
}

.features {
  margin-bottom: 24px;
}

.featureItem {
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  * {
    color: inherit;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.featureIconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  // margin-top: 2px;
}

.btn {
  width: 100%;
}

.trialNotice {
  margin-top: 16px;
  text-align: center;
  color: var(--gray60);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
