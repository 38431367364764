.container {
  margin-bottom: 16px;
  overflow-x: auto;
}

.table {
  border-radius: 8px;
  border: 1px solid var(--gray10);
  font-size: 14px;
  line-height: 22px;
  min-width: 800px;
}

.tableHeader {
  font-weight: 500;
  border-bottom: 1px solid var(--gray10);
  background: var(--slate-100);

  .tableCell {
    white-space: nowrap;
  }
}

.tableRow {
  min-height: 60px;
  display: flex;
  width: 100%;

  &:not(.tableHeader):nth-child(2n) {
    background: var(--slate-100);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray10);
  }
}

.tableCell {
  padding: 8px 12px;
  display: flex;
  align-items: center;

  &:nth-child(1) {
    width: 60px;
    justify-content: center;
  }

  &:nth-child(2) {
    flex: 1;
  }

  &:nth-child(3) {
    width: 116px;
  }

  &:nth-child(4) {
    width: 44px;
    justify-content: center;
  }

  &:nth-child(5) {
    width: 144px;
  }

  &:nth-child(6) {
    width: 90px;
    justify-content: center;
    white-space: nowrap;
  }
}

.tableContentWrapper {
  max-height: 640px;
  overflow-y: auto;
}

.tableContentWrapper {

}
