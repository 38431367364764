@import "src/assets/styles/mixines";

.section {
  max-width: 1080px;
}

.tabs {
  margin-bottom: 20px;
}

.content {
  border: 1px solid var(--gray07);
  background: #fff;
  border-top: none;
  border-radius: 0 0 6px 6px;
  margin-bottom: 20px;
}

.container {
  @include --tablet {
    display: flex;
  }
}

.contentBlock {
  background: #fff;

  &.leftSide {
    padding: 16px;
    border-radius: 0 0 0 6px;
  }

  &.rightSide {

    position: relative;
    border-radius: 0 0 6px 0;
    // background: var(--emerald50);
  }

  @include --tablet-s {
    &.leftSide {
      padding: 32px;
    }
  }

  @include --tablet {
    &.leftSide {
      width: 480px;
    }

    &.rightSide {
      flex: 1;
      max-width: 600px;
      position: relative;
      border-radius: 0 0 6px 0;
      // background: var(--emerald50);
    }
  }
}

.backgroundBlock {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 120px;
  border-radius: 0 0 8px 8px;
  background: var(--accent304);
}

.blockMain {
  position: relative;
  z-index: 5;

  .hidden {
    display: none;
  }
}

.blockHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  div {
    display: flex;
    align-items: center;
  }
}

.blockBackgroundHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 92px;
  border-radius: 0 0 8px 8px;
  background: var(--accent304);
  padding: 0 32px;
  margin-bottom: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  div {
    display: flex;
    align-items: center;
  }
}

.blockTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.checkIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.proxyTypeGroup {
  margin-bottom: 32px;
}

.pricingBlock {
  margin-bottom: 24px;
}

.resultContent {
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px;

  @include --tablet-s {
    padding: 0 32px 32px;
  }
}

.resultTabsWrapper {
  margin-bottom: 24px;
  display: flex;
}

.resultMain {
  margin-bottom: 16px;
}

.guideLink {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  transition: 0.2s;

  span {
    text-decoration-line: underline;
  }

  &:hover {
    color: var(--gray100);
  }
}

.bookIconWrapper {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.resultInputGroup {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.inputGroupBottomNotice {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.resultsLeftLabel {
  width: 65px;
}

.formContainer {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
}

.formWrapper {
  margin-bottom: 16px;
}

.configurationsList {
  margin-bottom: 32px;
}

.configurationItem {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.maskAcc {
  margin-bottom: 16px;
}

.outputMaskBlock {
  margin-bottom: 24px;
}

.resultBlock {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  /*height: 214px;
  max-height: 214px;
  width: 100%;
  overflow-y: scroll;
  border-radius: 8px;
  border: 1px solid var(--gray30);
  background: var(--brand-white);*/

  .resultBlockContent {
    padding-left: 0;
  }
}

.resultList {
  // padding: 10px 8px 10px 8px;
}

.resultListItem {
  display: flex;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  list-style: initial;
}

.resultListItemNumber {
  margin-right: 8px;
  white-space: nowrap;
  padding-left: 10px;
  text-align: right;
  flex: 0 1 25px;
}

.resultListItemValue {
  flex: 1;
}

.resultControls {
  display: flex;
}

.resultControlBtn {
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.whitelistBlock {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.14px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

.loaderContainer {
  margin: 70px auto 0;
  color: var(--accent120);

  svg {
    width: 40px;
    height: 40px;
  }
}

.optionFlag {
  width: 22px;
  height: 16px;
}

.resultNotice {
  margin-bottom: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  b {
    font-weight: 700;
  }
}

.resultExample {
  padding: 16px;
  border-radius: 8px;
  background: var(--accent700);
  color: var(--brand-white);
  font-family: "IBM Plex Mono", monospace;
  overflow-wrap: anywhere;
}

.optionsAccordion {
  margin-bottom: 24px;
}

.advancedOptionsInputGroup {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.radio {
  &:not(:last-child) {
    margin-right: 24px;
  }
}

.listResultHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 8px;

  @include --tablet-s {
    flex-direction: row;
    align-items: center;
    padding: 0 0 0 16px;
  }
}

.connectionsInputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @include --tablet-s {
    margin-bottom: 0;
  }

  .connectionsInput {
    border: none;
    width: 64px;
  }
}

.connectionsInputLabel {
  margin-right: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: var(--brand-white);
}

.listResultHeaderControls {
  display: flex;
}

.listResultHeaderBtn {
  &:not(:last-child) {
    margin-right: 5px;
  }
}

.proxyCheckerBtnContainer {
  display: flex;
  margin-bottom: 24px;
}

.listResultProxyCheckerNotice {
  margin-left: 16px;
}