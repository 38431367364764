@import "src/assets/styles/mixines";

.container {
  display: none;

  @include --tablet-l {
    display: block;
  }
}

.title {
  margin-bottom: 16px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;

  i {
    color: var(--accent100);
    font-style: normal;
  }
}

.featuresList {
  display: grid;
  grid-row-gap: 4px;
  grid-column-gap: 4px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-radius: 16px;
  overflow: hidden;
}

.feature {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: var(--accent304);
}

.featureIconWrapper {
  width: 28px;
  height: 28px;
  margin-bottom: 4px;


  svg {
    width: 28px;
    height: 28px;
  }
}

.featureContent {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  white-space: nowrap;

  &.underlined {
    text-decoration: underline;
  }
}

.flagsList {
  display: flex;
  align-items: center;

  &.hidden {
    display: none;
  }
}

.flag {
  width: 28px;
  height: 18px;
  border-radius: 2px;
  overflow: hidden;
  margin-left: 8px;
}

.tootipList {
  list-style: inside;

  li {
    list-style: inside;
  }
}